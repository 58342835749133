import React, { useCallback } from 'react';
import { HeadingMedium } from 'baseui/typography';
import { useStyletron } from 'baseui';
import { Button } from 'baseui/button';
import PropTypes from 'prop-types';
import sideImg from "../../../static/images/sidebar.png"

const actionBtnStyle = {
  left: '50%',
  position: 'relative',
  transform: 'translateX(-50%)',
  borderRadius: '5px'
}

const CarouselSliderElement = (props) => {
  const {
    key,
    background_color,
    background_image,
    title,
    button_text,
    button_url,
    image,
    description
  } = props;
  const [css, theme] = useStyletron();

  // ------------------------> Event Function ----------------------------- //

  const onClickActionButton = useCallback(() => {
    window.open(button_url, '_blank');
  }, [button_url])

  // ------------------------> Render Function ---------------------------- //
  const renderActionButton = useCallback(() => {
    if (!button_text) {
      return null;
    }
    return (
      <Button
        className={css(actionBtnStyle)}
        overrides={{
          BaseButton: { style: { width: '40%' } },
        }}
        onClick={onClickActionButton}
      >
        {button_text}
      </Button>

    );
  }, [button_text, button_url])

  return (
    <div className={css({ width: '100%' })} key={key}>
      <div
        className={css({
          position: 'relative',
          width: '100%',
          left: 0,
          top: 0,
          height: '100vh',
          backgroundImage: `url(${background_image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        })}
      >
        <div
          className={css({
            position: 'relative',
            float: 'left',
            width: '100%',
            height: 'auto',
            top: '45%',
            transform: 'translateY(-50%)',
          })}
        >
          <img
            src={image}
            className={css({
              maxWidth: '80%',
              maxHeight: '55vh',
              left: '50%',
              position: 'relative',
              transform: 'translateX(-50%)',
              // boxShadow: slide.boxShadow,
            })}
          ></img>
          <HeadingMedium
            style={{
              textAlign: 'center',
              marginTop: '0px',
              marginBottom: '0px',
              color: 'white',
            }}
          >
            {/* {title} */}
          </HeadingMedium>
          <div
            className={css({
              left: '50%',
              position: 'relative',
              transform: 'translateX(-50%)',
            })}
            style={{
              textAlign: 'center',
              width: '55%',
              color: 'white',
              padding: '24px',
              fontSize: '12px'
            }}
          >
            {/* {description} */}
          </div>
          {/* {renderActionButton()} */}
        </div>
      </div>
    </div>

  )
}

CarouselSliderElement.propTypes = {
  key: PropTypes.string,
  background_color: PropTypes.string,
  title: PropTypes.string,
  button_text: PropTypes.string,
  button_url: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string
}

export default CarouselSliderElement;