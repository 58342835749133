import * as React from 'react';
import { useStyletron } from 'baseui';
import { Cell } from 'baseui/layout-grid';
import { HeadingXLarge, ParagraphSmall } from 'baseui/typography';
import { Notification, KIND } from "baseui/notification";
import { StatefulPopover, PLACEMENT } from 'baseui/popover';
import { Button } from 'baseui/button';
import { navigate } from 'gatsby';

const defaultIcon = ({ theme, css }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={css({
      position: 'relative',
      float: 'left',
      marginTop: theme.sizing.scale100,
    })}
  >
    <circle cx="16" cy="16" r="16" fill="#192F57" />
    <path
      d="M13.4 14.5L18.17 6.24C17.47 6.09 16.75 6 16 6C13.6 6 11.4 6.85 9.68 8.25L13.34 14.6L13.4 14.5ZM25.54 13C24.62 10.08 22.39 7.74 19.54 6.66L15.88 13H25.54ZM25.8 14H18.31L18.6 14.5L23.36 22.75C25 20.97 26 18.61 26 16C26 15.31 25.93 14.65 25.8 14ZM12.54 16L8.64 9.25C7.01 11.03 6 13.39 6 16C6 16.69 6.07 17.35 6.2 18H13.69L12.54 16ZM6.46 19C7.38 21.92 9.61 24.26 12.46 25.34L16.12 19H6.46ZM17.73 19L13.83 25.76C14.53 25.91 15.25 26 16 26C18.4 26 20.6 25.15 22.32 23.75L18.66 17.4L17.73 19Z"
      fill="#00A3FF"
    />
  </svg>
);

/**
 *
 * @param {*} options
 */
const RightSidePageHeader = ({
  icon, // header main icon
  title, // header title
  dropDownContent, // what to show on drop down click
  rightButtonTitle, // right button titleTemplate
  headerDescription, // header description
  onRightButtonClick,
  flag,
  rightContent,
  iconCss = {},
  previewIcon,
  onPreviewIconClick,
}) => {
  // init base ui css and theme
  const [css, theme] = useStyletron();
  const [onHover, setOnHover] = React.useState(false);
  const defaultClickByPass = () => false;

  return (
    <>
      <Cell span={12}>
        <div
          className={css({
            position: 'relative',
            float: 'left',
            width: '100%',
            marginTop: flag ? '29px !important' : 0,
            zIndex: 1
          })}
        >
          <div
            className={css({
              float: 'left',
              marginTop: '7px',
            })}
          >
            {/* ICON */}
            {icon && (
              <img
                src={icon}
                alt={'Icon'}
                className={css({
                  position: 'relative',
                  float: 'left',
                  marginTop: '9px',
                  height: '28px',
                  width: '28px',
                  marginBottom: '0px',
                  ...iconCss,
                })}
              ></img>
            )
            }

            {/* HEADING */}
            <HeadingXLarge
              className={css({
                position: 'relative',
                float: 'left',
                marginLeft: icon ? theme.sizing.scale550 : '0px',
                marginBottom: '0px !important',
                textTransform: 'capitalize',
                // marginLeft: icon ? 0 : '1rem'
              })}
            >
              {title}
            </HeadingXLarge>

            {/* DROP DOWN ICON */}
            {dropDownContent && (
              <StatefulPopover
                content={dropDownContent}
                placement={PLACEMENT['bottomRight']}
                returnFocus
                overrides={{
                  Body: {
                    style: ({ $theme }) => ({
                      marginTop: '50px',
                    }),
                  },
                }}
              >
                <svg
                  width="18"
                  height="11"
                  viewBox="0 0 18 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={css({
                    position: 'relative',
                    float: 'left',
                    marginLeft: theme.sizing.scale550,
                    marginTop: theme.sizing.scale600,
                    cursor: 'pointer',
                  })}
                >
                  <path
                    d="M0.5 0.666656V5.25831L9 10.8166L17.5 5.25831V0.666656L9 6.225L0.5 0.666656Z"
                    fill={theme.colors.primaryA}
                  />
                </svg>
              </StatefulPopover>
            )}
          </div>

          {previewIcon && (
            <svg
              width="22"
              height="15"
              viewBox="0 0 22 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={onPreviewIconClick}
              className={css({
                position: 'relative',
                float: 'left',
                marginLeft: theme.sizing.scale550,
                marginTop: '24px',
                cursor: 'pointer',
              })}
            >
              <path
                d="M11 0C6 0 1.73 3.11 0 7.5C1.73 11.89 6 15 11 15C16 15 20.27 11.89 22 7.5C20.27 3.11 16 0 11 0ZM11 12.5C8.24 12.5 6 10.26 6 7.5C6 4.74 8.24 2.5 11 2.5C13.76 2.5 16 4.74 16 7.5C16 10.26 13.76 12.5 11 12.5ZM11 4.5C9.34 4.5 8 5.84 8 7.5C8 9.16 9.34 10.5 11 10.5C12.66 10.5 14 9.16 14 7.5C14 5.84 12.66 4.5 11 4.5Z"
                fill={theme.colors.primaryA}
                fillOpacity="0.5"
              />
            </svg>
          )}

          {rightContent && (
            <div
              className={css({
                position: 'relative',
                float: 'right',
              })}
            >
              {rightContent}
            </div>
          )}

          {/* RIGHT BUTTON */}
          {rightButtonTitle && (
            <Button
              className={css({
                position: 'relative',
                float: 'right',
              })}
              onClick={onRightButtonClick || defaultClickByPass}
            >
              {rightButtonTitle}{' '}
              <svg
                width="8"
                height="6"
                viewBox="0 0 8 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={css({
                  marginLeft: theme.sizing.scale550,
                })}
              >
                <path
                  d="M8 0.333344V2.86667L4 5.93334L0 2.86667V0.333344L4 3.40001L8 0.333344Z"
                  fill={theme.colors.primaryB}
                />
              </svg>
            </Button>
          )}
        </div>
      </Cell>
      {headerDescription && <Cell span={12}>
        {headerDescription && headerDescription.indexOf('Looks like you manage organizations') !== -1 ? (
          <div
            onClick={() => { navigate('/organizations') }}
            onMouseEnter={() => setOnHover(true)}
            onMouseLeave={() => setOnHover(false)}
          >
            <Notification
              overrides={{
                Body: { style: { width: 'auto', cursor: 'pointer', textDecoration: onHover ? 'underline' : 'none', whiteSpace: 'pre-line' } },
              }}
            >
              {headerDescription}
            </Notification>
          </div>
        ) : (
          <ParagraphSmall
            className={css({
              color: theme.colors.primary500,
              whiteSpace: 'pre-line',
              margin: '0 !important'
            })}
          >
            {headerDescription}
          </ParagraphSmall>
        )}
      </Cell>}
    </>
  );
};

export default RightSidePageHeader;
